import { CommonModule, IMAGE_CONFIG, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './app.layout.component.html',
  styleUrl: './app.layout.component.css',
  imports: [CarouselModule, CommonModule, NgOptimizedImage],
  providers : [
    { provide: IMAGE_CONFIG, 
      useValue: { 
        disableWarnings: true, 
      },
    }
  ]
})
export class AppLayoutComponent {
  slidesElipsePower:any = [
    {
      src: "/Trab_Realizados/Elipse_Power/Shopping_Colinas.jpg",
      title: "Shopping Colinas",
      description: "Desenvolvimento e Testes em Fábrica de aplicação em Elipse Power para monitoramento de Subestação GIS do Shopping Colinas (São José dos Campos-SP)."
    },
    {
      src: "/Trab_Realizados/Elipse_Power/ituverava.png",
      title: "Parque Solar Ituverava",
      description: "Testes em Campo de aplicação em Elipse Power para monitoramento de Subestação da Usina Fotovoltaica de Ituverava, pertencente a ENEL (Tabocas do Brejo Velho-BA).",
    },
    {
      src: "/Trab_Realizados/Elipse_Power/Rio_Linha_4.png",
      title: "Metrô Rio Linha 4",
      description: "Atualização de aplicação Elipse Power com Testes em Campo para monitoramento de Subestação São Conrado Metrô Rio, Linha 4 (Rio de Janeiro-RJ).",
    },
    {
      src: "/Trab_Realizados/Elipse_Power/CPTM_L13.png",
      title: "CPTM – L13 (Aeroporto Guarulhos)",
      description: "Desenvolvimento e Testes em Campo de aplicações em Elipse Power para monitoramento de 4 Subestações e 1 Centro de Operação da linha 13 da CPTM (Guarulhos-SP).",
    },
    {
      src: "/Trab_Realizados/Elipse_Power/Eolica_Delfina.jpg",
      title: "Parque Eólico Delfina",
      description: "Testes em Campo de aplicação em Elipse Power para monitoramento de Subestação da Usina Eólica de Delfina, pertencente a ENEL (Campo Formoso-BA).",
    },
    {
      src: "/Trab_Realizados/Elipse_Power/Eolica_CER.png",
      title: "Parque Eólico CER",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em Elipse Power para monitoramento de duas Subestações do Parque Eólico da CER Laranjeiras e Gentio do Ouro II (Xique-Xique-BA)."
    },
    {
      src: "/Trab_Realizados/Elipse_Power/Henry_Borden.jpg",
      title: "Usina Hidroelétrica Henry Borden",
      description: "Testes em Campo de aplicação em Elipse Power para monitoramento da Usina Hidroelétrica Henry Borden (Cubatão-SP)."
    },
    {
      src: "/Trab_Realizados/Elipse_Power/Folha_larga.png",
      title: "Parque Eólico Folha Larga",
      description: "Testes em Fábrica e Testes em Campo de aplicação em Elipse Power para monitoramento da Subestação do Parque Eólico Folha Larga, pertencente a Casa dos Ventos (Campo Formoso-BA)."
    },
    {
      src: "/Trab_Realizados/Elipse_Power/rib_concalves.jpeg",
      title: "Parque Solar Ribeiro Gonçalves",
      description: "Testes em Fábrica de aplicação em Elipse Power para monitoramento da Subestação da Usina Fotovoltaica de Ribeiro Gonçalves, pertencente a Echoenergia (Curitiba-PR)."
    },
    {
      src: "/Trab_Realizados/Elipse_Power/scala.jpg",
      title: "Subestação Data Center SCALA",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em Elipse Power para monitoramento da Subestação GIS Scala, para expansão do data center (Barueri-SP)."
    },
    {
      src: "/Trab_Realizados/Elipse_Power/barreiras.jpg",
      title: "Parque Solar Barreiras",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em Elipse Power para monitoramento da Usina Fotovoltaica Barreiras (SE Barreias III), pertencente a Echoenergia (Barreiras-BA)."
    },
    {
      src: "/Trab_Realizados/Elipse_Power/sapiranga.jpg",
      title: "Subestação Sapiranga",
      description: "Desenvolvimento, Testes em fábrica e Testes em Campo de aplicação em Elipse e Concentrador RTAC para monitoramento da Subestação Sapiranga, pertencente a RGE/CPFL (Sapiranga-RS)."
    },
  ]

  slidesElipseE3:any = [
    {
      src: "/Trab_Realizados/Elipse_E3/renault.png",
      title: "Renault",
      description: "Desenvolvimento e Testes em Campo de aplicação em Elipse E3 para monitoramento de Subestação de 69kV 6ºAnel da Renault (São José dos Pinhais-PR)."
    },
  ]

  slidesEcoSUI:any = [
    {
      src: "/Trab_Realizados/EcoSUI/granja.png",
      title: "Subestação Granja",
      description: "Desenvolvimento e Testes em Fábrica de aplicação em EcoSUI com controladora C264 para monitoramento da Subestação Granja, pertencente a ENEL (Granja-CE)."
    },
  ]

  slidesIfix:any = [
    {
      src: "/Trab_Realizados/Ifix/heineken.jpg",
      title: "Subestação Heineken",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em iFIX para monitoramento da Subestação pertencente a Heineken (Ponta Grossa-PR)."
    },
  ]

  slidesIndusoft:any = [
    {
      src: "/Trab_Realizados/Indusoft/portobello.png",
      title: "Fábrica Portobello",
      description: "Desenvolvimento e Testes em Campo de aplicação em Indusoft para monitoramento da Subestação e dados da fábrica de cerâmica (Tijucas-SC)."
    },
  ]

  slidesRTAC:any = [
    {
      src: "/Trab_Realizados/RTAC/rondonia.jpeg",
      title: "Móvel Energisa Rondônia",
      description: "Desenvolvimento e Testes em Fábrica de aplicação em RTAC (concentrador e Tela Supervisório) para monitoramento da Subestação Móvel pertencente a Energisa Rondônia (Blumenau-SC)."
    },
    {
      src: "/Trab_Realizados/RTAC/sapiranga.jpg",
      title: "Subestação Sapiranga",
      description: "Desenvolvimento, Testes em fábrica e Testes em Campo de aplicação em Elipse e Concentrador RTAC para monitoramento da Subestação Sapiranga, pertencente a RGE/CPFL (Sapiranga-RS)."
    },
  ]

  slidesSAGE:any = [
    {
      src: "/Trab_Realizados/SAGE/ratones.jpg",
      title: "Subestações Ratones-Biguaçu",
      description: "Desenvolvimento, Testes em fábrica e Testes em Campo de aplicação em SAGE para monitoramento de bay na Subestação Biguaçu (continente) e Subestação GIS Ratones (ilha) com monitoramento (sistema DTS) de cabo de transmissão submarino/subterrâneo, pertencente a CTEEP com acesso a subestação Eletrosul (Florianópolis-SC)."
    },
    {
      src: "/Trab_Realizados/SAGE/cachoeira_grande.jpg",
      title: "Subestação Cachoeira Grande",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em SAGE para monitoramento da Subestação Cachoeira Grande, pertencente a Amazonas Energia (Manaus-AM)."
    },
    {
      src: "/Trab_Realizados/SAGE/peixe.png",
      title: "Subestação Barra do Peixe",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em SAGE para monitoramento da SE Barra do Peixe com adição de dois transformadores de 230/138/13,8kV, pertencente a Eletronorte (Ribeirãozinho-MT)."
    },
    {
      src: "/Trab_Realizados/SAGE/tabocas.png",
      title: "Subestação Tabocas do Brejo Velho",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em SAGE para monitoramento da Subestação de Tabocas do Brejo Velho da CHESF (Tabocas do Brejo Velho-BA)."
    },
    {
      src: "/Trab_Realizados/SAGE/gusa.jpg",
      title: "Subestação Gusa Nordeste",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em SAGE para monitoramento da Subestação Gusa Nordeste, que alimenta a fábrica de aço pertencente à AVB (Açailândia-MA)."
    },
    {
      src: "/Trab_Realizados/SAGE/brasilia.png",
      title: "Subestação Brasília Geral",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em SAGE para monitoramento da SE Brasília Geral com a modernização de todo setor 34kV, pertencente a FURNAS (Brasília-DF)."
    },
    {
      src: "/Trab_Realizados/SAGE/utinga.jpg",
      title: "Subestação Utinga",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em SAGE para modernização da Subestação de Utinga, pertencente a Eletronorte (Belém-PA)."
    },
    {
      src: "/Trab_Realizados/SAGE/rio_verde.jpeg",
      title: "Subestação Rio Verde",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em SAGE para monitoramento da Subestação Rio Verde para adequação devido a instalação de um novo banco de capacitores 138 kV e instalação de sistema VMware para servidor RARP/RDP, preparação de softwares para coleta automática de oscilografia. Empreendimento pertencente a FURNAS (Rio Verde-GO)."
    },
    {
      src: "/Trab_Realizados/SAGE/padre_fialho.jpg",
      title: "Subestação Padre Fialho",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em SAGE para monitoramento da SE Padre Filaho, pertencente a State Grid (Matipó-MG)."
    },
    {
      src: "/Trab_Realizados/SAGE/gna.jpg",
      title: "UTE GNA II",
      description: "Testes em Campo de aplicação em SAGE para monitoramento da Subestação de GNA II e troca de informações com a Usina, pertencente a GNA (São João da Barra-RJ)."
    },
    {
      src: "/Trab_Realizados/SAGE/leopoldina.png",
      title: "Subestação Leopoldina",
      description: "Desenvolvimento e Testes em Fábrica de aplicação em SAGE para monitoramento da Subestação Leopoldina, pertencente a Zopone (Curitiba-PR)."
    },
    {
      src: "/Trab_Realizados/SAGE/serra_da_mesa.jpeg",
      title: "Subestação Serra da Mesa",
      description: "Testes em Campo de aplicação em SAGE para expansão da subestação de Serra da Mesa. Instalação de sistema VMware para servidor RARP/RDP, preparação de softwares para coleta automática de oscilografia. Empreendimeno pertencente a FURNAS (Minaçu -GO)."
    },
    {
      src: "/Trab_Realizados/SAGE/porto_velho.jpg",
      title: "Subestação Coletora Porto Velho",
      description: "Desenvolvimento, Testes em Fábrica e Testes em Campo de aplicação em SAGE para inserção de dois reatores de 500kV e modernização de sistema SCADA em rede PRP-HSR-RSTP, nova estrutura de redes station e process bus. Projeto pertencente a Eletrobrás Eletronorte (Porto Velho-RO)."
    }, 
    {
      src: "/Trab_Realizados/SAGE/eletrosul.png",
      title: "Testes em Fábrica para Eletrosul",
      description: "Testes em Fábrica a serviço da Eletrobrás Eletrosul para validação de expansão de diversas subestações."
    },   
  ]

  slidesTreinamentos:any = [
    {
      src: "/Trab_Realizados/Treinamentos/chesf.png",
      title: "CHESF",
      description: "Treinamento de Desenvolvimento, Operação e Manutenção para trabalhos com SAGE em sistema Linux e equipamentos de rede (Switchs, RedBox, Terminal Server e Servidores) para a CHESF (Recife-PE)."
    },
    {
      src: "/Trab_Realizados/Treinamentos/eletrosul.png",
      title: "ELETROSUL",
      description: "Treinamento para configuração do Terminal Server RS416 Siemens Ruggedcom para o cliente final Eletrosul (Curitiba-PR)."
    },
    {
      src: "/Trab_Realizados/Treinamentos/state_grid.png",
      title: "STATE GRID",
      description: "Treinamento para Migrações de estações SAGE da versão/update 23 para versão/update 29 (Curitiba-PR)."
    },
    {
      src: "/Trab_Realizados/Treinamentos/cemig.png",
      title: "CEMIG",
      description: "Treinamento de Desenvolvimento, Operação e Manutenção para trabalhos com SAGE em sistema Linux e equipamentos de rede (Switchs, RedBox, Terminal Server e Servidores) para o cliente final CEMIG (Belo Horizonte-MG)."
    },
  ]

  slidesA8000:any = [
    {
      src: "/Trab_Realizados/A8000/vista_alegre.jpg",
      title: "Parque Solar Vista Alegre",
      description: "Configuração e testes de controlador A8000 (SIEMENS) para monitoramento do Serviço Auxiliar da Subestação da Usina Fotovoltaica de Vista Alegre e do Serviço Auxiliar na casa de comando no Bay de Conexão, ambos pertencentes a ATLAS (Janaúba-MG)."
    },
  ]

  slidesInfoKW:any = [
    {
      src: "/banner/Calendar.png",
      title: "9 ANOS",
      description: "Fundada em 2015, este ano a empresa completa 9 anos de mercado."
    },
    {
      src: "/banner/ihm.png",
      title: "SUPERVISÓRIOS E IHMS",
      description: "A KWaut está preparada para atender demandas em sistemas supervisórios (SCADA), Controladores (CLPs e Gateways) e IHMs:",
      app1: "Elipse",
      app2: "Indusoft",
      app3: "Ifix",
      app4: "Weintek",
      app5: "Siemens A8000",
      app6: "Entre Outros...",
      app7: "SAGE",
      app8: "WinCC",
      app9: "ActionNet",
      app10: "SEL RTAC",
      app11: "CLPs variados",
    },
    {
      src: "/banner/Treinamentos.png",
      title: "TREINAMENTOS",
      description: "A KWaut está preparada para atender a diversas demandas de treinamentos de Operação, Manutenção e Desenvolvimento de Sistemas de Supervisão, IHMs, CLPs e Controladores."
    },
    {
      src: "/banner/raio.png",
      title: "ENERGIA",
      description: "Parametrização de Sistemas Supervisórios, IHMs e Controladoras para Usinas Hidrelétricas, Parques Eólicos, Solares e subestações."
    },
    {
      src: "/banner/saneamento.png",
      title: "SANEAMENTO",
      description: "Parametrização de Sistemas Supervisórios, IHMs e CLPs para Captação, Tratamento e Distribuição de Água."
    },
    {
      src: "/banner/industria.png",
      title: "INDÚSTRIA",
      description: "Parametrização de Sistemas Supervisórios, IHMs e CLPs para diversos Processos Industriais."
    },
    {
      src: "/banner/olho.png",
      title: "OPERAÇÃO ASSISTIDA",
      description: "Operação assistida de Subestações, Usinas, estações de tratamento de água e demais sistemas industriais. Serviço composto por um conjunto de atividades que permitam o treinamento e capacitação da equipe do cliente operar o sistema em questão."
    },
    {
      src: "/banner/ferramentas.png",
      title: "SUPORTE E MANUTENÇÃO",
      description: "Possuímos suporte especializado interno para apoio ao time de campo da KWaut. Também oferecido aos clientes, caso desejem esta contratação."
    },
    {
      src: "/banner/remoto.png",
      title: "TESTES REMOTOS",
      description: "A KWaut também está preparada para atendimento de testes remotos."
    },
    {
      src: "/banner/consultoria.png",
      title: "CONSULTORIA",
      description: "Equipe capacitada para orientações técnicas em todas as fases dos projetos de sistemas digitais de automações."
    },
    {
      src: "/banner/visitas.png",
      title: "VISITAS TÉCNICAS",
      description: "Equipe para vistas técnicas e levantamento de dados para novos projetos."
    },
  ]

  customOptionsTrabalhos: OwlOptions = {
    loop: true,
    autoplay:true,
    stagePadding: 200,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoHeight: true,
    autoWidth: true,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }
  
  
  customOptionsBanner: OwlOptions = {
    loop: true,
    autoplay:true,
    stagePadding: 0,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoHeight: false,
    autoWidth: false,
    nav:true,
    navText : ["<img src='/prev-button.png'>","<img src='/next-button.png'>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
  }
}
